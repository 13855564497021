import React, { Component } from "react"
import { graphql } from "gatsby"
import { sortReviewsByDate } from "../utils/utils"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import StaticHeading from "./index/StaticHeading"
import Sizzle from "./index/Sizzle"
import ImageText from "./index/ImageText"
import AllRatingsReviews from "../components/SocialReviews/AllSocialReviews"
import FirstTime from "./index/FirstTime"
import InTheCommunity from "./index/InTheCommunity"
import ReferringProvider from "./index/ReferringProvider"
import Services from "./index/Services"
import LearnMoreCards from "./index/LearnMoreCards"
import StaticHero from "./index/StaticHero"
import { TestimonialPreview } from "../components/Cards"
import { Section } from "../components/Containers"

import ProgramToast from "../components/program-toast/ProgramToast"
// import Toast from "../components/Toast/Toast"

class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      playing: false
    }
  }

  render() {
    const { pageContext } = this.props
    let post
    const { language } = this.props.pageContext

    if (pageContext && pageContext.language) {
      switch (language) {
        case "en":
          post = this.props.data.allUniquePagesJson.nodes[0]
          break
        case "es":
          post = this.props.data.spanishIndex.nodes[0]

          break
        default:
          post = this.props.data.allUniquePagesJson.nodes[0]
      }
    } else {
      post = this.props.data.allUniquePagesJson.nodes[0]
    }

    const staticStyle = {
      position: "relative",
      overflow: "unset",
      width: "100%",
      zIndex: 1
    }
    const sizzleButtonStyle = {
      position: "absolute",
      top: 0,
      zIndex: 200
    }

    let allReviews = this.props.data.allReviews.nodes
    if (language === "es") allReviews = this.props.data.allReviewsEs.nodes

    allReviews = sortReviewsByDate(this.props.data.allReviews.nodes).slice(
      0,
      30
    )
    const reviewsHalf = allReviews.length / 2

    // const sortedReviews = sortReviewsByDate(allReviews).slice(0, 30)
    // // shuffleArray(allReviews);
    // console.log(allReviews.length)
    // let fixedFacewallReviews = []
    // if (sortedReviews.length >= 8) {
    //   fixedFacewallReviews = sortedReviews.slice(0, 8)
    // } else {
    //   fixedFacewallReviews = sortedReviews.slice(0, 4)
    // }

    let schemaData = null
    if (language === "en") {
      schemaData = {
        presets: post.schemas.presets,
        customSchema: post.schemas.custom,
        dateModified: this.props.pageContext.dateModified,
        language,
        metaTitle: post.metaTitle,
        description: post.metaDescription,
        path: this.props.location.pathname
      }
    }

    return (
      <SharedStateProvider>
        <Layout
          className={`main-homepage ${language === "es" ? "es" : null}`}
          language={language}
          layoutClass="homepage-fab"
          noFab
          pageTitle="main-homepage">
          <SEO
            title={post.metaTitle}
            description={post.metaDescription}
            schemaData={schemaData}
            pathname={this.props.location.pathname}
            lang={language}
          />

          <div className="static-container" style={staticStyle}>
            {post.hasSizzle ? (
              <Sizzle
                playing={this.state.playing}
                sizzleButtonStyle={sizzleButtonStyle}
                youtube={post.sizzleYoutube}
                post={post}
                language={language}>
                <StaticHeading
                  playing={this.state.playing}
                  youtube={post.sizzleYoutube}
                  heading={post.hero.heading}
                  subheading={post.hero.subheading}
                  language={language}
                />
              </Sizzle>
            ) : (
              <StaticHero
                post={post}
                heading={post.hero.heading}
                subheading={post.hero.subheading}
                bgImage={post.hero.bgImage}
                language={language}
              />
            )}

            {language == "en" && (
              <>
                <div className="testimonial-wall">
                  {allReviews.map((t, idx) => (
                    <TestimonialPreview
                      hoverLeft={idx + 1 > reviewsHalf}
                      withHoverCard
                      key={t.youtube}
                      language={language}
                      youtube={t.youtube}
                      image={t.mainPhotoPublicId}
                      reviewerName={t.reviewerName}
                      city={t.city}
                      procedure={t.mainProcedureDone}
                    />
                  ))}
                </div>

                <div className="columns">
                  <div className="column">
                    <TestimonialPreview
                      language={language}
                      youtube={"UspUSYD_Mbc"}
                      image={"CYFS/DEV/V2/watch-patient-story-cover"}
                      heading="Patient Overview"
                      subheading="Watch the Video"
                    />
                  </div>
                  <div className="column">
                    <TestimonialPreview
                      language={language}
                      youtube={"WnAvu0ukjAg"}
                      image={"CYFS/DEV/V2/watch-referring-provider-cover"}
                      heading="Referring Doctors"
                      subheading="Watch the Video"
                    />
                  </div>
                </div>
              </>
            )}

            <LearnMoreCards language={language} post={post.learnMoreCards} />

            <ImageText language={language} post={post} textImage noReverse />

            <Services language={language} post={post.services} />

            {/* <FixedFacewallHomepage
              language={language}
              heading={
                language === "es"
                  ? "Escuche Acerca de Nuestros Pacientes"
                  : "Hear From Our Patients"
              }
              reviews={fixedFacewallReviews}
            /> */}

            <InTheCommunity
              colorBack
              language={language}
              data={post.inTheCommunity}
            />

            {/* <HeadingVideo post={post} /> */}

            {/* <AAAHC post={post.homeAAAHC} language={language}/> */}

            <ReferringProvider
              colorBack={language === "es"}
              // textOnly
              language={language}
              data={post.referringProvider}
            />
            {/*
            <Exparel post={post.homeExparel} />
            <ASIRD post={post.homeAsird} /> */}

            <FirstTime
              colorBack={language === "en"}
              language={language}
              post={post}
              noReverse
            />

            {post.homeRatingsReviews.hasThisSection && (
              <Section>
                <AllRatingsReviews
                  animation
                  heading={post.homeRatingsReviews.heading}
                  language={language}
                  buttonHref={post.homeRatingsReviews.buttonOne.href}
                  buttonText={post.homeRatingsReviews.buttonOne.buttonText}
                />
              </Section>
            )}
          </div>

          {/* <Toast>
            <p>
              We’re proud to have once again been nominated for Best of Las
              Vegas.{" "}
              <a href="http://www.votebolv.com/canyon" target="_blank">
                Vote here!
              </a>
            </p>
          </Toast> */}
          {/* <ProgramToast
            program="smile again"
            endDate="September 26 2023"
            href="/smileagain/"
          /> */}
        </Layout>
      </SharedStateProvider>
    )
  }
}

export const pageQuery = graphql`
  query reviewsIndexQuery {
    allReviews: allReviewsJson(
      filter: { reviewType: { eq: "Patient" }, reviewLanguage: { ne: "ESP" } }
    ) {
      nodes {
        ...FacewallData
      }
    }
    allReviewsEs: allSpanishReviewsJson(
      filter: { reviewType: { eq: "Patient" }, reviewLanguage: { eq: "ESP" } }
      limit: 4
    ) {
      nodes {
        title
        reviewerName
        reviewType
        reviewLanguage
        thumbnailPublicId
        mainProcedureDone
        monthYear {
          month
          year
        }
      }
    }
    allUniquePagesJson(filter: { title: { eq: "/" } }) {
      nodes {
        schemas {
          presets
          custom
        }
        hasSizzle
        hero {
          heading
          subheading
          bgImage
        }
        homeSectionOne {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          heading
          imageId
          text
        }
        imageTexts {
          hasThisSection
          textLeft
          leftColWidth
          leftContentColWidth
          middleColWidth
          rightColWidth
          text
          imageId
          buttons {
            button {
              buttonText
              appearance
              buttonText
              href
              destination
            }
          }
        }
        homeExparel {
          hasThisSection
          imageId
          blurb
          button {
            buttonText
            href
          }
        }
        homeAsird {
          blurb
          hasThisSection
          imageId
          button {
            buttonText
            href
          }
        }
        homeAAAHC {
          blurb
          heading
          hasThisSection
          backgroundImage
          logos {
            imageId
          }
          button {
            useButton
            buttonText
            href
          }
        }
        learnMoreCards {
          blurb
          hasThisSection
          heading
          cards {
            blurb
            heading
            imageId
            button {
              buttonText
              href
            }
          }
        }
        services {
          blurb
          hasThisSection
          heading
          items {
            blurb
            heading
            imageId
            button {
              title
              href
            }
          }
          buttons {
            button {
              buttonText
              appearance
              buttonText
              href
              destination
            }
          }
        }
        homeRatingsReviews {
          hasThisSection
          heading
          buttonOne {
            buttonText
            href
          }
        }
        homeHeadingVideo {
          hasThisSection
          heading
          youtube
          youtubePhotoPublicId
          youtubePhotoPublicIdMobile
        }
        inTheCommunity {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          leftRight
          subheading
          text
        }
        referringProvider {
          youtube
          imageCaption
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          text
        }
        firstTimePatient {
          buttons {
            button {
              href
              buttonText
              destination
              appearance
            }
          }
          heading
          text
        }
        metaTitle
        sizzleYoutube
        metaDescription
        title
      }
    }
    spanishIndex: allSpanishUniquePagesJson(filter: { title: { eq: "/es/" } }) {
      nodes {
        hasSizzle
        mobileHeading
        hero {
          heading
          subheading
          bgImage
        }
        homeSectionOne {
          buttonOne {
            buttonText
            href
          }
          buttonTwo {
            buttonText
            href
          }
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          heading
          imageId
          text
        }
        learnMoreCards {
          blurb
          hasThisSection
          heading
          cards {
            blurb
            heading
            imageId
            button {
              buttonText
              href
            }
          }
        }
        imageTexts {
          hasThisSection
          textLeft
          leftColWidth
          leftContentColWidth
          middleColWidth
          rightColWidth
          text
          imageId
          buttons {
            button {
              buttonText
              appearance
              buttonText
              href
              destination
            }
          }
        }
        homeExparel {
          hasThisSection
          imageId
          blurb
          button {
            buttonText
            href
          }
        }
        homeAsird {
          blurb
          hasThisSection
          imageId
          button {
            buttonText
            href
          }
        }
        homeAAAHC {
          blurb
          heading
          hasThisSection
          backgroundImage
          logos {
            imageId
          }
          button {
            useButton
            buttonText
            href
          }
        }
        services {
          blurb
          hasThisSection
          heading
          items {
            blurb
            heading
            imageId
            button {
              title
              href
            }
          }
          buttons {
            button {
              buttonText
              appearance
              buttonText
              href
              destination
            }
          }
        }
        homeRatingsReviews {
          hasThisSection
          heading
          buttonOne {
            buttonText
            href
          }
        }
        homeHeadingVideo {
          hasThisSection
          heading
          youtube
          youtubePhotoPublicId
          youtubePhotoPublicIdMobile
        }
        seoHeading
        inTheCommunity {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          leftRight
          subheading
          text
        }
        referringProvider {
          youtube
          imageCaption
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          text
        }
        firstTimePatient {
          buttons {
            button {
              href
              buttonText
              destination
              appearance
            }
          }
          heading
          text
        }
        metaTitle
        sizzleYoutube
        metaDescription
        title
        svgHeading
      }
    }
  }
`

export default IndexPage
